//import Vue from 'vue';
import * as func from './functions/_function';                      //関数関係
import * as animation from './functions/_animation';                //アニメーション関係
//import * as bliderBox from './functions/_bliderBox'               //ブラインだーボックス
//import * as offcanvas from './functions/_offcanvas';              //オフキャンバス
//import * as pagetop from './functions/_pagetop';                  //ページトップ
import * as loading from './functions/_loading';                    //ローディング
//import * as smoothScroll from './functions/_smoothScroll';        //スムーススクロール
//import Modal from '../../node_modules/bootstrap/js/dist/modal';   //モーダルライブラリ
import 'mobile-detect';                                             //デバイス判定・ブラウザ判定ライブラリ
import * as checkUA from './functions/_checkUA';                    //デバイス判定・ブラウザ判定処理
import 'fullpage.js';

// グローバル関数
// ------------------------------------------------------------

let CURRENT_SCROLL_POS = 0;
let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let BLAKEPOINT_HEADER_SP_MODE = 1024;
let SCROLLDOWN_FLAG = true;
let DEBUG_MODE = false;

// モジュール/グローバル処理
// ============================================================

animation.scrollObserver();                                   // アニメーション処理
checkUA.addUAClass();                                         // UAおよびデバイスクラス追加処理
//headerFixed.setHeaderFixed();                               // ヘッダー固定
//smoothScroll.setSmoothScroll();                             // スムーススクロール

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  
  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  // pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
  
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
})

// 固有関数実行
// ============================================================

$(function(){
  pageHomeScript();
  uploadFileView();
});

// トップページ固有
// ============================================================

function pageHomeScript(){
  if($('.home').length){

    // ローディングアニメーション
    loading.initLoading(DEBUG_MODE);
    
    //デバイス判定によるfullpagejsの解除トリガの基準となる閾値を指定
    let responsiveHeight;
    
    if($('body').hasClass('isPC')){
      responsiveHeight = 700;
    } else if($('body').hasClass('isSP')){
      responsiveHeight = 415;
    }
    
    // DOCS
    // https://uhaha.net/fullpage/
    $('#fullpage').fullpage({
      autoScrolling:true,
      sectionSelector: '.js_fullPageSection',
      slideSelector: '.js_fullPageSlide',
      responsiveHeight:responsiveHeight, //デバイス幅が指定数値以下の高さになるとfullpagejsを解除
      fitToSection: true,
      verticalCentered: true, 
      onLeave:function(index,nextIndex,direction){
        if(nextIndex === 2 && direction === "down"){
          toggleUi(true);
          doEvelation01Animation(true);
        }else if(nextIndex === 1 && direction === "up"){
          toggleUi(false);
          doEvelation01Animation(false);
        }
      },
      afterResponsive:function(isResponsive){}
    });
  
    // ローカルストレージリセット処理
    // -------------------
    localStorage.removeItem('attachedFileName01');
    localStorage.removeItem('attachedFileName02');
  }
}

function doEvelation01Animation(show){
  
  let $target = $('.layer_evelation01');
  let className = 'js_isActive';
  $target.addClass(className);
  
  if(show === true){
    $target.addClass(className);
  }else{
    $target.removeClass(className);
  }
  
}


function toggleUi(show){
  
  let $targetHedder = $('.header');
  let $targetBottomUi = $('.global_ui');
  let className = 'js_isHidden';
  
  if(show === true){
    $targetHedder.removeClass(className);
    $targetBottomUi.removeClass(className);
  }else{
    $targetHedder.addClass(className);
    $targetBottomUi.addClass(className);
  }
  
}

// ローディング完了後にアニメーションを発火させる関数
// ------------------------------------------------------------

function loadingCallbackAnimation(delay,cb){
  
  // ローディング完了フラグを監視
  let watchLoadedFlagClass = setInterval(function () {
    countUp();
  }, 1);
  
  const countUp = () =>{
    if($('body').hasClass('js_isLoaded')){
      
      setTimeout(function () {
        
        //引数で渡された関数を実行
        cb();
      }, delay);
      clearInterval(watchLoadedFlagClass);
      
    }
  }
}

// ローディング時にコールバックさせるアニメーション関数をセット/制御
// ------------------------------------------------------------

function setLoadingCbAnimation() {
  
  //ターゲットクラスの有無を確認
  if($('.js_loading_cb_animation').length){
    
    let targetElms = $('.js_loading_cb_animation');
    
    targetElms.each(function(i){
      let delay = $(this).data('animation-delay');
      $(this).delay(i * delay).queue(function(next){
        $(this).addClass('js_isActive animate__animated');
        next();
      });
    })
  }
}

loadingCallbackAnimation(600,setLoadingCbAnimation);



// フォーム画面での画像選択時にファイル名を表示させる記述
// ============================================================

function uploadFileView(){

  //添付したファイル名を取得する
  let $insertTargetElm01 = $('.js-upload-filename01');
  let $insertTargetElm02 = $('.js-upload-filename02');

  //ファイル名の初期値をセット
  let file01Name = getLocalStrageFileName('attachedFileName01');
  let file02Name = getLocalStrageFileName('attachedFileName02');
  
  //ローカルストレージにファイル名が存在する場合、初回読み込み時にインサート
  insertFileName(file01Name,$insertTargetElm01);
  insertFileName(file02Name,$insertTargetElm02);


  // 添付した瞬間に添付ファイル名を取得する
  // ------------------------------------------------------------
  $('.js-upload-file01').on('change',function(){
    //ファル名を取得
    file01Name = $(this).prop('files')[0].name;
    // ローカルストレージセット
    localStorage.setItem('attachedFileName01',file01Name);
    // インサート処理
    insertFileName(file01Name,$insertTargetElm01);
  });
  $('.js-upload-file02').on('change',function(){
    //ファル名を取得
    file02Name = $(this).prop('files')[0].name;
    // ローカルストレージセット
    localStorage.setItem('attachedFileName02',file02Name);
    // インサート処理
    insertFileName(file02Name,$insertTargetElm02);
  });

  // ローカルストレージからデータをゲット
  // ------------------------------------------------------------
  function getLocalStrageFileName(localStrageKey){
    return localStorage.getItem(localStrageKey);
  }

  // インサート関数
  // ------------------------------------------------------------
  function insertFileName(fileName,$targetElm){
    //file01Nameがnull以外の場合のみインサート処理実行
    if(fileName){
      $targetElm.text(fileName).addClass('js_isActive');
    }
  }

  //リセット処理
  $('.mw_wp_form_confirm .submit_btn').on('click',function(){
    localStorage.removeItem('attachedFileName01');
    localStorage.removeItem('attachedFileName02');
  })
  
}

